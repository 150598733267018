<template>
  <main class="vcc-main">
	<h1>Реестр организаций</h1>
	<nav>
		<ul class="link-list">
			<li>
				<button-card
					:to="{ name: 'CreateOrganization' }"
					text="Добавить организацию"
				>
					<template #icon>
						<icon-charts />
					</template>
				</button-card>
			</li>
			<li>
				<button-card
					:to="{ name: 'OrganizationList' }"
					text="Просмотр реестра"
				>
					<template #icon>
						<icon-video-page />
					</template>
				</button-card>
			</li>
		</ul>
	</nav>
  </main>
</template>

<script>
import IconCharts from '@/assets/svg/IconCharts.vue'
import IconVideoPage from '@/assets/svg/IconVideoPage.vue'
import ButtonCard from '@/components/common/ButtonCard.vue'
export default {
	name: 'OrganizationRegistry',
	components: {
		ButtonCard,
		IconCharts,
		IconVideoPage,
	}
}
</script>


<style>
	.vcc-main {
		padding: 0 20px;
	}
	.link-list {
		list-style: none;
		padding: 0; margin: 30px 0 0 0;
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-auto-rows: max-content;
		gap: 30px;
	}
</style>
